import './application.js';
import '~/stylesheets/signups.css';

// import 'https://cdn.plyr.io/3.7.3/plyr.css'

import AnimatedNumber from 'stimulus-animated-number'

import { Application } from '@hotwired/stimulus'
const application = Application.start()
application.register('animated-number', AnimatedNumber)
